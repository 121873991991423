<template>
  <div>  <img alt="Sywert" src="https://images0.persgroep.net/rcs/QOKapRK79cb6p8mjjKuDmqyw2w0/diocontent/180505042/_fitwidth/694/?appId=21791a8992982cd8da851550a453bd7f&quality=0.8">
  </div>
  <div>Sywert heeft <span style="font-weight: bold">{{ result }}</span> en das dus <span style="font-weight: bold">{{ resultInterest }}</span> rendement op zijn vermogen</div>
</template>

<script>
export default {
  name: "home",
  props: {
    start: {
      type: Number,
      default: 9000000
    },
    startDate: {
      type: Date,
      default: new Date('May 1, 2020')
    },
    interest: {
      type: Number,
      default: 1.06
    },
  },
  data() {
    return {
      result: "",
      resultInterest: ""
    }
  },
  mounted() {
    setInterval(() => {
      this.calculateInterest()
    }, 1000);
  },
  methods: {
    calculateInterest() {
      var currentDate = new Date(Date.now());
      var seconds = ((currentDate.getTime() - this.startDate.getTime()) / 999);
      var days = seconds / 86399;
      var years = days / 364.0;
      var value = this.start * Math.pow(this.interest, years);

      this.result = (new Intl.NumberFormat('nl-NL', {
        style: 'currency',
        currency: 'EUR'
      }).format(value));
      this.resultInterest = (new Intl.NumberFormat('nl-NL', {
        style: 'currency',
        currency: 'EUR'
      }).format(value - this.start))
    }
  }

}
</script>

<style scoped>

</style>